export const constraintOptions = [
    { id: 'MANDATORY', label: 'Nulls', description: 'Null values' },
    { id: 'UNIQUE', label: 'Duplicates', description: 'Field values must be unique' },
    { id: 'RANGE', description: 'Range Constraint', label: 'Within a certain range (including the thresholds set)' },
    {
        id: 'RANGE_EXCLUDING',
        description: 'Range Constraint',
        label: 'Within a certain range (excluding the thresholds set)',
    },
    { id: 'NOT_RANGE', description: 'Range Constraint', label: 'Outside a certain range' },
    {
        id: 'REGULAR_EXPRESSION',
        label: 'Matching with a regular expression',
        description: 'Field values must have an exact match with a certain regular expression',
    },
    {
        id: 'NOT_REGULAR_EXPRESSION',
        label: 'Not matching with a regular expression',
        description: 'Field values must have an exact match with a certain regular expression',
    },
    {
        id: 'CROSS_FIELD',
        label: 'Satisfying conditions between different fields',
        description: 'Field values must satisfy certain conditions which are executed from left to right',
    },
    {
        id: 'FOREIGN_KEY',
        label: 'Appearing in a different field',
        description: 'Field values must exist in a certain referenced field',
    },
    {
        id: 'NOT_FOREIGN_KEY',
        label: 'Not appearing in a different field',
        description: 'Field values must exist in a certain referenced field',
    },
];

export type ConstraintOptionsType = typeof constraintOptions[number];

export const outliersRuleOptions = [
    { id: 'DROP', label: 'Drop' },
    { id: 'DEFAULT_VALUE', label: 'Replace with default value' },
    { id: 'PREVIOUS_VALUE', label: 'Replace with previous value' },
    { id: 'MOST_FREQUENT_VALUE', label: 'Replace with most frequent value' },
    { id: 'MEAN_VALUE', label: 'Replace with mean value' },
    { id: 'MEDIAN_VALUE', label: 'Replace with median value' },
    { id: 'MIN_VALUE', label: 'Replace with min value' },
    { id: 'MAX_VALUE', label: 'Replace with max value' },
    { id: 'MOST_SIMILAR', label: 'Replace with most similar value' },
];

export const conditionsOptions = [
    { id: 'EQUAL', label: 'is equal to' },
    { id: 'NOT_EQUALS', label: 'is not equal to' },
    { id: 'GREATER_THAN', label: 'is greater than' },
    { id: 'SMALLER_THAN', label: 'is smaller than' },
];

export const notSupportedMultipleConstraints = ['UNIQUE', 'CROSS_FIELD', 'FOREIGN_KEY', 'NOT_FOREIGN_KEY'];

export enum MultipleOperator {
    Element = 'element',
    All = 'all',
    Any = 'any',
    None = 'none',
}
